<template>
    <div
        id="additional-files"
        class="tab-content"
    >
        <attachments
            key="additional-file"
            type="additional"
            attachments-type="additional"
            :files="order.files_add"
            :order-status="order.sw_status"
            :unread-files-count="+order.counters.unread_files_add_count"
            :sw-id="swId"
            loading-url="/api/file/additional/upload"
            delete-url="/api/file/additional/delete"
            @readFiles="onReadFiles"
            @onFilesDelete="onFilesDelete($event, 'additional')"
            @uploadedFiles="onUploadedFiles($event, 'additional')"
        />
    </div>
</template>

<script>
import Attachments from '@/components/account/orders/Attachments.vue'

export default {
    components: {
        Attachments
    },
    props: {
        order: {
            type: Object
        },
        swId: {
            type: Number
        }
    },
    methods: {
        onFilesDelete(file_id) {
            this.order.counters.files_add_count -= 1
            this.order.files_add = this.order.files_add.filter((item) => item.file_id !== file_id)
        },
        onUploadedFiles(files) {
            files.forEach((file) => {
                this.order.files_add.push(file)
                this.order.counters.files_add_count += 1
            })
        },
        onReadFiles() {
            if (this.order.counters.unread_files_add_count > 0) {
                // makeAsReadFiles(this.orderid)
                this.order.counters.unread_files_add_count = 0
            }
        }
    }
}
</script>
