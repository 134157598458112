<template>
    <div class="no-result">
        <h1
            class="no-result__title no-result__title_question"
            :class="`no-result__title_question-order`"
        >
            No Data
        </h1>
    </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
// @import 'src/assets/styles/site_specific_styles/customessaymeister.com/variables/_variables.scss';

.no-result {
    font-family: $font-family-base;
    margin: 10vh auto !important;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    &__title {
        text-transform: uppercase;
        color: rgba(175, 178, 185, .6);
        font-size: 40px;
        font-weight: 900;
        letter-spacing: 2.4px;
        text-align: center;
        margin: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;
        &_question {
            &:before {
                content: '';
                display: block;
                width: 100px !important;
                height: 100px !important;
                background: url('~@/assets/img/svg/billing_notfound.svg') no-repeat !important;
                margin-bottom: 20px;
                background-size: contain;
                background-position: center;
            }
        }
    }

    &__description {
        color: rgba(175, 178, 185, .6);
        font-size: 24px;
        font-weight: 500;
        text-align: center;
        white-space: pre-line;
    }

    &__action {
        margin-top:24px;
        text-align: center;
    }

    .btn-base{
        margin-top: 20px;
    }
}
</style>
