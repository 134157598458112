<template lang="html">
    <div
        class="attachments"
        style="padding: 0 30px;"
    >
        <div
            id="view-add-form"
            class="file-uploader"
        >
            <div
                v-if="this.$route.hash === '#files' && showUploaderFor"
                class="final-info"
            >
                <p
                    class="orders-details-tabs__tab-description"
                >
                    <InformationOutline />
                    Maximum allowed upload filesize is {{ maxFileSize | file_size }}.
                </p>
                <br>
                <br>
                <p>To finalise the project, please upload the file that contains your work (.doc or .rtf formats)</p>
                <br>
                <b>To comply with our terms of service, please make sure that:</b>
                <ol class="final-info__list">
                    <li class="final-info__list-point">
                        1. Your document is complete and written in accordance with the client's requirements
                    </li>
                    <li class="final-info__list-point">
                        2. The content is written by you and there is absolutely no plagiarism in the content
                    </li>
                    <li class="final-info__list-point">
                        3. The paper follows the correct citation style and documents all sources correctly
                    </li>
                </ol>
            </div>
            <file-uploader
                v-if="showUploaderFor"
                :id="id"
                ref="file_uploader"
                :uploaded-files="files"
                :max-file-size="maxFileSize"
                :use-multiple="type === 'additional'"
                :loading-url="loadingUrl"
                @success="onUploadSuccess"
            />
            <NoResult
                v-if="!files.length"
                type="writers-messages"
            />
            <attachments-table
                :uploaded-files="files"
                :delete-url="deleteUrl"
                :unread-files-count="unreadFilesCount"
                :uploaded-files-own="uploadedFilesOwn"
                @onDelete="onFilesDelete"
            />
        </div>
    </div>
</template>

<script>

import NoResult from '@/components/common/NoResult';
import filtersMixin from '@/mixins/filtersMixin'
import FileUploader from '@/components/FileUploader.vue';
import AttachmentsTable from '@/components/AttachmentsTable.vue';
import InformationOutline from 'mdi-vue/InformationOutline';

export default {
    components: {
        NoResult,
        AttachmentsTable,
        FileUploader,
        InformationOutline
    },
    mixins: [
        filtersMixin
    ],
    props: {
        files: {
            type: Array
        },
        type: {
            type: String,
            required: true
        },
        orderStatus: {
            type: String
        },
        deleteUrl: {
            type: String,
            required: true
        },
        loadingUrl: {
            type: String,
            required: true
        },
        unreadFilesCount: {
            type: Number,
            required: false,
            default: 0
        },
        attachmentsType: {
            type: String
        }
    },
    data() {
        return {
            // maxFileSize: 30,
            uploadedFilesOwn: []
        }
    },
    computed: {
        maxFileSize() {
            return Number(process.env.VUE_APP_MAX_BODY_SIZE) || 104857600
        },
        id() {
            return this.$route.params.id;
        },
        showUploaderFor() {
            return this.orderStatus === 'PENDING' || this.orderStatus === 'COMPLETE' || this.orderStatus === 'REVISION'
        }
    },
    created() {
        this.$emit('readFiles');
    },
    methods: {
        onFilesDelete(file_id) {
            this.$emit('onFilesDelete', file_id)
        },
        onUploadSuccess(files) {
            if (this.attachmentsType === 'main') {
                files.file.forEach((item) => {
                    this.uploadedFilesOwn.push(item)
                    this.$emit('uploadedFiles', { file: item, order: files.order })
                })
            } else {
                this.uploadedFilesOwn = files
                this.$emit('uploadedFiles', files)
            }
        }
    }
}
</script>

<style lang='scss'>
    .image_progress{
        position: absolute;
        top: 50%;
        transform: translateX(20px) translateY(-50%);
        background: #fff;
        padding: 5px;
        border-radius: 4px;
    }
    .file-uploader .orders-details-tabs__tab-description {
        margin: 20px 0 0 0;
    }
    .final-info{
        padding: 30px;
        background: rgba(17, 97, 224, 0.1);
        border-radius: 15px;
        & p{
            margin-bottom: 10px;
        }
        margin-top: 20px;
        &__list{
            margin-top: 10px;
        }
        &__list-point{
            line-height: 1.3;
        }
    }
</style>
